<template>
    <section id="ProjSamarati">
        

        <div class="container">
        
        <!-- PAGE TITLE -->
            <page-title 
                title="Studio Multidisciplinare - Roberta Samarati" 
                subtitle="Operazione di branding per uno studio multidisciplinare" 
            />
        <!-- --------------------- -->

        <img src="@/assets/imgs/proj-samarati/logo.png" alt="" style="margin: 30px auto 0 auto; max-width: 60%">

        <!-- CONCEPT -->
            <text-and-img title='Concept' img=''> 
                <p>Il cliente cercava un logo che esprimesse l'idea di un lavoro multidisciplinare, un team che lavora fondendo più discipline e diversi punti di vista.</p>
                <p>Il visivo deve apparire leggero e comunicare empatia e familiarità futuri pazienti.</p>
            </text-and-img>
        <!-- --------------------- -->
        
        <!-- BRANDING -->
            <text-and-img title='Branding' imgType="contain" img='proj-samarati/logo.png'>
                Il nuovo logo dispone due principali chiavi di lettura:
                <ul style="margin: 10px 0">
                    <li>La prima: un tavolo con attorno sedute delle persone che comunicano tra di loro e al centro del tavolo un sorriso;</li>
                    <li>La seconda: un insieme di discipline (rappresentate da cerchi) che ruotano attorno e interagiscono con la stilizzazione di una faccia felice</li>
                </ul>
                La percezione sincrona di questi due livelli di lettura vuole esprimere appieno l'anima di questo Studio con leggerezza e semplicità.
            </text-and-img>
        <!-- --------------------- -->

        </div>
    </section>
</template>

<script>
import PageTitle from '../../components/small/PageTitle.vue'
// import Gallery from '../../components/works-project/Gallery.vue'
// import Showreel from '../../components/works-project/Showreel.vue'
import TextAndImg from '../../components/works-project/TextAndImg.vue'
// import ReadThe from '../../components/works-project/ReadThe.vue'

export default {
    name: 'ProjSamarati',
    components: {
        PageTitle,
        // Showreel,
        TextAndImg,
        // Gallery,
        // ReadThe,
    },
    metaInfo: {
        title: 'Studio Samarati',
        meta: [{
            vmid: 'description',
            name: 'description',
            content: 'Restyle del logo per "Studio Multidisciplinare - Roberta Samarati", il nuovo logo dispone due principali chiavi di lettura, la prima: un tavolo con attorno sedute delle persone che comunicano tra di loro e al centro del tavolo un sorriso, quello di chi grazie al confronto degli esperti è potuto diventare felice. La seconda: un volto felice circondato da diverse discipline che interagiscono con lui',
        }],
    },
    data(){
        return{
            //
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.container{
    display: flex;
    flex-direction: column;
}

</style>