<template>
    <section class="TextAndImg">
        <h3>{{title}}</h3>
        <div class="content">
            <div class="slot"><slot></slot></div>
            <div v-if="img != '' && img != null" class="img">
                <img :src="require(`@/assets/imgs/${img}`)" alt="" :style="imgType == 'contain' ? 'object-fit: contain; padding: 10px' : 'object-fit: cover;'">
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'NameComponent',
    props:{
        title: String,
        img: String,
        imgType: String
    },
    data(){
        return{
            //
        }
    },
    methods: {
        //
    }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';

.TextAndImg{
    margin-top: $work-comp-mt;
    padding: 15px 30px;
    background-color: $c-window-bg;
    border: $main-borders;
    box-shadow: $shadow;

    h3{
        @include f-secondary-top-title;
        margin: 15px 0;
    }

    .content{
        display: flex;
        font-size: 1.25rem;
        font-weight: 400;
    }

    .img{
        @include img-fit-cover;
        height: 300px;
        min-width: 390px;
        flex-grow: 1;
        border: $main-borders;
        margin-left: 30px;
        align-self: center;
        background-color: white;
    }
    .content{
        & > div.slot{
            max-width: 680px;
            flex-grow: 2;

            p:first-of-type{
                margin-top: 0;
            }
            p{
                margin-top: 10px;
            }
        }
    }
}

@media screen and (max-width: 900px){
    .TextAndImg{
        .img{
            min-width: 290px;
        }
    }
}
@media screen and (max-width: 760px){
    .TextAndImg{
        .content{
            flex-direction: column;
        }
        .img{
            min-width: 100px;
            width: 100%;
            margin: 30px;
            margin-bottom: 20px;
            height: 250px;
        }
    }
}

</style>